import './bootstrap'

import Vue from 'vue'
import VTooltip from 'v-tooltip'
import VueRouter from 'vue-router'
import VueCurrencyFilter from 'vue-currency-filter'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VTooltip, {
  defaultTrigger: 'hover focus click'
})

Vue.use(VueRouter)
Vue.use(VueSocialSharing)

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined
})

Vue.component('app', () => import( /* webpackChunkName: 'main' */ './app.vue'))
Vue.component('construction', () => import( /* webpackChunkName: 'construction' */ './views/partials/construction.vue'))
Vue.component('error', () => import( /* webpackChunkName: 'error' */ './views/partials/error.vue'))
Vue.component('success', () => import( /* webpackChunkName: 'success' */ './views/partials/success.vue'))
Vue.component('vue-magnifier', () => import( /* webpackChunkName: 'magnifier' */ './views/partials/magnifier.vue'))
Vue.component('send-password', () => import( /* webpackChunkName: 'sendpassword' */ './views/partials/passwords/send.vue'))
Vue.component('reset-password', () => import( /* webpackChunkName: 'resetpassword' */ './views/partials/passwords/reset.vue'))
Vue.component('pinch-zoom', () => import( /* webpackChunkName: 'pinch-zoom' */ 'vue-pinch-zoom'))

const router = new VueRouter({
  base: '/',
  // base: '/BasingerAS',
  mode: 'history',
  routes: [{
      path: '/',
      name: 'home',
      props: true,
      component: () => import( /* webpackChunkName: 'home' */ './views/home.vue'),
    },
    {
      path: '/verified',
      name: 'verified',
      props: {
        title: 'Congratulations!',
        text: 'You have successfully verified your email address.'
      },
      component: () => import( /* webpackChunkName: 'notice' */ './views/notice.vue')
    },
    {
      path: '/not-verified',
      name: 'not-verified',
      props: {
        title: 'Ooops!',
        text: 'Please login first and press that link from email again.'
      },
      component: () => import( /* webpackChunkName: 'notice' */ './views/notice.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      props: true,
      component: () => import( /* webpackChunkName: 'profile' */ './views/profile.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import( /* webpackChunkName: 'cart' */ './views/cart.vue')
    },
    {
      path: '/checked-out',
      name: 'checked-out',
      props: {
        title: 'Thank you!',
        text: 'You have successfully ordered your perfect sound configuration.'
      },
      component: () => import( /* webpackChunkName: 'notice' */ './views/notice.vue')
    },
    {
      path: '/wishlist',
      name: 'wishlist',
      component: () => import( /* webpackChunkName: 'wishlist' */ './views/wishlist.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import( /* webpackChunkName: 'dashboard' */ './views/admin/dashboard.vue')
    },
    {
      path: '/products',
      name: 'products',
      props: true,
      component: () => import( /* webpackChunkName: 'products' */ './views/admin/products.vue')
    },
    {
      path: '/signin',
      name: 'signin',
      props: true,
      component: () => import( /* webpackChunkName: 'login' */ './views/login.vue')
    },
    {
      path: '/checkout',
      name: 'checkout',
      props: true,
      component: () => import( /* webpackChunkName: 'checkout' */ './views/register.vue')
    }
  ],
})

const app = new Vue({
  el: '#app',
  router
})
